var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showFilter
        ? _c(
            "div",
            [
              _vm.showUserFilter
                ? _c(
                    "vs-select",
                    {
                      ref: "select",
                      staticClass: "w-full",
                      attrs: {
                        autocomplete: "",
                        placeholder:
                          "$t('selecione-um-usuario-para-filtrar-os-eventos')",
                        "danger-text": "Seleção inválida",
                        noData: " ",
                        label: _vm.$t("usuario"),
                      },
                      model: {
                        value: _vm.selectedUser,
                        callback: function ($$v) {
                          _vm.selectedUser = $$v
                        },
                        expression: "selectedUser",
                      },
                    },
                    [
                      _c("vs-select-item", {
                        attrs: { value: null, text: "Todos" },
                      }),
                      _vm._l(_vm.userOptions, function (model) {
                        return _c("vs-select-item", {
                          key: model.classroom_user_id,
                          attrs: {
                            value: model.classroom_user_id,
                            text:
                              model.name +
                              " <br/><div class='text-sm'>" +
                              model.email +
                              "<div>",
                          },
                        })
                      }),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.showSeverityFilter
                ? _c(
                    "vs-select",
                    {
                      ref: "selectSeverity",
                      staticClass: "w-full",
                      attrs: {
                        autocomplete: "",
                        placeholder: "$t('selecione-uma-criticidade')",
                        "danger-text": "Seleção inválida",
                        noData: " ",
                        label: _vm.$t("criticidade"),
                      },
                      model: {
                        value: _vm.selectedSeverity,
                        callback: function ($$v) {
                          _vm.selectedSeverity = $$v
                        },
                        expression: "selectedSeverity",
                      },
                    },
                    [
                      _c("vs-select-item", {
                        attrs: { value: null, text: "Nenhuma" },
                      }),
                      _c("vs-select-item", {
                        attrs: { value: "L", text: "Baixa" },
                      }),
                      _c("vs-select-item", {
                        attrs: { value: "M", text: "Média" },
                      }),
                      _c("vs-select-item", {
                        attrs: { value: "H", text: "Alta" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("vs-divider", { staticClass: "mb-1 mt-3" }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "proctoring-timeline" },
        [
          _c("vx-timeline", {
            key: _vm.timelineFormattedEvents,
            staticClass: "w-full",
            attrs: {
              timeFormat: "literal",
              html: true,
              data: _vm.timelineFormattedEvents,
            },
          }),
        ],
        1
      ),
      _vm.events.length > 0
        ? _c("transition-group", {
            staticClass: "proc-event-list",
            attrs: { tag: "div", name: _vm.transition },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }