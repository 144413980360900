<template>
  <div>
    <div v-if="showFilter">
      <vs-select
        v-if="showUserFilter"
        class="w-full"
        autocomplete
        v-model="selectedUser"
        placeholder="$t('selecione-um-usuario-para-filtrar-os-eventos')"
        danger-text="Seleção inválida"
        noData=" "
        :label="$t('usuario')"
        ref="select"
      >
        <vs-select-item
          :value="null"
          text="Todos"
        />
        <!-- memory -->
        <vs-select-item
          :key="model.classroom_user_id"
          :value="model.classroom_user_id"
          :text="`${model.name} <br/><div class=\'text-sm\'>${model.email}<div>`"
          v-for="(model) in userOptions"
        />
      </vs-select>
      <vs-select
        v-if="showSeverityFilter"
        class="w-full"
        autocomplete
        v-model="selectedSeverity"
        placeholder="$t('selecione-uma-criticidade')"
        danger-text="Seleção inválida"
        noData=" "
        :label="$t('criticidade')"
        ref="selectSeverity"
      >
        <vs-select-item
          :value="null"
          text="Nenhuma"
        />
        <vs-select-item
          value="L"
          text="Baixa"
        />
        <vs-select-item
          value="M"
          text="Média"
        />
        <vs-select-item
          value="H"
          text="Alta"
        />
      </vs-select>
      <vs-divider class="mb-1 mt-3"/>
    </div>

    <div class="proctoring-timeline">
      <vx-timeline
        :key="timelineFormattedEvents"
        timeFormat="literal"
        class="w-full"
        :html="true"
        :data="timelineFormattedEvents"/>
    </div>

    <transition-group v-if="events.length > 0" tag="div" :name="transition" class="proc-event-list">

      <!-- <div
        :key="event.id" v-for="event in filteredEvents"
        :class="containerClasses(event)"
      >
        <div
          v-if="event && event.supervisor"
          class="row-span-2 grid grid-cols-8 grid-rows-2 gap-x-12"
        >
          <vs-avatar
            v-show="event && event.supervisor"
            class="row-span-2 col-span-1"
            :src="event.supervisor.user.profile_picture"
          />
          <label class="col-span-7 font-bold">
            {{event.supervisor.user.name}}
          </label>
          <label class="col-span-7">
            {{$t(`proctoring.event.type.${event.type}`)}}
          </label>
        </div>

        <div v-else
          class="row-span-2 grid grid-cols-6 grid-rows-2 gap-x-12"
        >
          <vs-avatar
            class="row-span-2 col-span-1"
            :src="undefined"
          />
          <label class="col-span-5 font-bold text-danger">
            Sistema
          </label>
          <label class="col-span-5">
            Evento Automático - {{$t(`proctoring.event.type.${event.type}`)}}
          </label>
        </div>

        <div class="w-full mt-2">
          <div style="max-height:100px;" class="break-words overflow-y-auto" v-html-safe="JSON.parse(event.data)"></div>
        </div>
        <vs-divider class="my-2"/>
      </div> -->
    </transition-group>
  </div>
</template>

<script>
import VxTimeline from '@/components/timeline/VxTimeline.vue'
import {proctoring} from '@/util/Constants'
export default {

  components: {
    VxTimeline
  },

  props: {
    events: {
      type: Array,
      default: () => []
    },
    streams: {
      type: Array,
      default: () => []
    },
    transition: {
      type: String,
      default: 'slide-fade'
    },
    enabled: {
      type: Boolean,
      default: true
    },
    itemStyling: {
      type: Function,
      default: (event) => ({
        container: 'py-2 flex gap-1 flex-col',
      })
    },
    showFilter: {
      type: Boolean,
      default: true
    },
    showUserFilter: {
      type: Boolean,
      default: false,
    },
    showSeverityFilter: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    selectedUser: null,
    selectedSeverity: null
  }),

  computed:{
    filteredEvents() {
      let events = this._.cloneDeep(this.events)
      if (this.selectedUser && this.showUserFilter) {
        events = events.filter(
          (event) => (
            this.selectedUser === event.classroom_user_id
          )
        )
      }
      if (this.selectedSeverity && this.showSeverityFilter) {
        events = events.filter(
          (event) => (
            this.selectedSeverity === event.severity
          )
        )
      }

      return events
    },
    timelineFormattedEvents() {
      const events = this._.cloneDeep(this.filteredEvents)

      events.sort((a, b) => {
        const dateA = new Date(a.data.emitted_at)
        const dateB = new Date(b.data.emitted_at)
        if (dateA > dateB) return 1
        if (dateA < dateB) return -1
        return 0
      })

      const formattedEvents = events.map((event) => {
        const eventText = this._.get(event,  'data.text', this.$t(`proctoring.placeholder.${event.type}`))
        const icon = this._.get(proctoring, `events.icons.${event.type}`, 'InfoIcon')
        return {
          title: this.$t(`questionnaire_events.event.${event.type}`),
          desc: eventText,
          color: (event.severity && proctoring.events.colors[event.severity]) || '',
          icon: icon,
          time: event.data.emitted_at
        }
      })

      formattedEvents.sort((e1, e2) => this.$moment(e1.time) < this.$moment(e2.time))

      return formattedEvents
    },
    userOptions() {
      const options = this.streams.map((stream) => {
        return stream.user
      })
      return _.uniqBy(options, 'email')
    }
  },

  methods: {
    highlight(event) {
      if (this.enabled) {
        this.$emit('highlight', event)
      }
    },
    containerClasses(event) {
      return `proc-event-list-container ${this.itemStyling(event).container}`
    }
  }
}
</script>

<style lang="scss">
.vx-timeline {
  margin-left: 10px;
}

.proctoring-timeline {
  padding: 10px;
}
</style>
